<template>
  <router-link
    class="table__row"
    :to="{ name: 'task', params: { id: task.id } }"
    >
    <div class="table__col">
      <inline-svg v-if="task.priority" :src="require('../../assets/icons/whatshot_24px_outlined.svg')" />
      <inline-svg v-else :src="require('../../assets/icons/ac_unit_24px.svg')" />
    </div>
    <div class="table__col">
      <inline-svg v-if="task.status === 'open'" :src="require('../../assets/icons/assignment_late_24px.svg')" />
      <inline-svg v-else :src="require('../../assets/icons/assignment_turned_in_24px.svg')" />
    </div>
    <div class="table__col">
      {{ getTaskTheme(task.type) }}
    </div>
    <div class="table__col">
      {{ task.openDate | moment("DD.MM.YYYY") }}
    </div>
    <div class="table__col">
      {{ task.closeDate | moment("DD.MM.YYYY") }}
    </div>
    <div class="table__col">
      {{ getUserName(task.user) }}
    </div>
    <div class="table__col">
      <div v-if="task.user.type === 'owner'" class="type-block">
        <inline-svg :src="require('../../assets/icons/home_24px.svg')" />
        Owner
      </div>
      <div v-else class="type-block">
        <inline-svg :src="require('../../assets/icons/account_circle_24px_outlined.svg')" />
        Tenant
      </div>
    </div>
  </router-link>
</template>

<script>
import CommonMixin from "../../mixins/Common.mixin";

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },

  mixins: [ CommonMixin ],

  data() {
    return {
      types: [
        {
          value: 'all',
          text: 'Все темы'
        },
        {
          value: 'user_documents_verification',
          text: 'Проверка личных документов'
        },
        {
          value: 'object_documents_verification',
          text: 'Проверка документов на недвижимость'
        },
        {
          value: 'chat_abuse',
          text: 'Жалоба из чата'
        },
        {
          value: 'review_dispute',
          text: 'Спорный отзыв'
        },
        {
          value: 'contract_dispute',
          text: 'Спор при прекращении договора'
        },
        {
          value: 'incoming_request',
          text: 'Входящий запрос'
        },
        {
          value: 'consultation',
          text: 'Консультация'
        },
        {
          value: 'law_and_security',
          text: 'Юридические или вопросы безопасности'
        },
        {
          value: 'else',
          text: 'Другое'
        },
        {
          value: 'fake_object',
          text: 'Жалоба на обьект'
        }
      ]
    }
  },

  methods: {
    getTaskTheme(type) {
      const result = this.types.find(el => el.value === type);
      if (result) {
        return result.text;
      }
      return '';
    }
  }

}
</script>

<style scoped lang="scss">
.table__row{
  .table__col{
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 27%;
    }
    &:nth-child(4) {
      width: 13%;
    }
    &:nth-child(5) {
      width: 13%;
    }
    &:nth-child(6) {
      width: 14.6%;
    }
    &:nth-child(7) {
      width: 11.6%;
    }
  }
  .type-block{
    ::v-deep svg{
      margin-right: 8px;
    }
    display: flex;
    align-items: center;
  }
}
</style>